import './App.css';

import React, { useState } from 'react';
import { useRef } from 'react';
import { useForm, ValidationError } from '@formspree/react';


function App() {

  
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);

  const handleScrollToSection1 = () => {
    section1Ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleScrollToSection2 = () => {
    section2Ref.current.scrollIntoView({ behavior: 'smooth' });
  };
 
    const [activeIndex, setActiveIndex] = useState(1); // Start with the third image (index 2)
  
    const images = [
      '/5.jpg',
      '/1.jpg',
      '/2.jpg',
      '/3.jpg',
      '/4.jpg',
    ];
  
    const handlePrev = () => {
      setActiveIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };
  
    const handleNext = () => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handleReload = () => {
      window.location.reload();
    };

    const [state, handleSubmit] = useForm("xblrylke");

    if (state.succeeded) {
      return <div className='flex flex-col w-full h-[100vh] justify-center items-center text-2xl text-center'>
      <p>
        Спасибо за заявку! <br /> Мы скоро с вами свяжемся
      </p>
      <br />
      <span className="inline-block p-3 text-yellow-200 rounded-full bg-yellow-200/80 mt-4 hover:cursor-pointer" onClick={handleReload}>
        <svg className="w-4 h-4 text-yellow-900 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
        </svg>
      </span>
    </div>
    
    }

  return (
    <div className="w-full justify-center bg-slate-50">
      

    <nav className="bg-slate-50 border-gray-200">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <p href="https://Stylary.com/" className="flex items-center space-x-3 rtl:space-x-reverse">
            {/* <img src="https://Stylary.com/docs/images/logo.svg" className="h-8" alt="Stylary Logo" /> */}
            <span className="self-center text-2xl font-semibold whitespace-nowrap text-yellow-800">Stylary</span>
        </p>
        {/* <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-yellow-950 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 " aria-controls="navbar-default" aria-expanded="false">
            <span className="sr-only">Open main menu</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
            </svg>
        </button> */}
        <div className="hidden w-full md:block md:w-auto" id="navbar-default">
          <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-slate-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0  ">
            
            <li>
              <p href="#" className="block py-2 px-3 text-yellow-950 rounded hover:text-yellow-700 hover:cursor-pointer">О Нас</p>
            </li>
            <li>
              <p href="#" className="block py-2 px-3 text-yellow-950 rounded hover:text-yellow-700 hover:cursor-pointer" onClick={handleScrollToSection1}>Наши Работы</p>
            </li>
            <li>
              <p href="#" className="block py-2 px-3 text-yellow-950 rounded hover:text-yellow-700 hover:cursor-pointer" onClick={handleScrollToSection2}>Свяжитесь с нами</p>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    
<div className='w-full flex justify-center mt-[2em]'>
<div id="animation-carousel" className="relative w-[95%] lg:w-[65%]" data-carousel="static">
      <div className="relative h-56 overflow-hidden rounded-lg md:h-96">
        {images.map((src, index) => (
          <div
            key={index}
            className={`absolute block w-full transition-opacity duration-200 ease-linear ${activeIndex === index ? 'opacity-100' : 'opacity-0'}`}
            style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
            data-carousel-item={activeIndex === index ? 'active' : ''}
          >
            <img src={src} className="block w-full" alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </div>
      <button
        type="button"
        className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
        onClick={handlePrev}
        data-carousel-prev
      >
        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30  group-focus:ring-4 group-focus:ring-white  group-focus:outline-none">
          <svg className="w-4 h-4 text-white  rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
          </svg>
          <span className="sr-only">Previous</span>
        </span>
      </button>
      <button
        type="button"
        className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
        onClick={handleNext}
        data-carousel-next
      >
        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30  group-hover:bg-white/50  group-focus:ring-4 group-focus:ring-white  group-focus:outline-none">
          <svg className="w-4 h-4 text-white  rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
          </svg>
          <span className="sr-only">Next</span>
        </span>
      </button>
    </div>

</div>

<div className='mt-[2em] w-full flex justify-center text-center'>
<div className='w-[95%] lg:w-[65%]'>
<h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl  ">Мы создаем уют и стиль в вашем доме</h1>
<p className="mb-6 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 ">В нашей компании мы предлагаем комплексные услуги по подбору и установке дизайнерских штор, жалюзи, карнизов и тюлей. Наши решения помогут вам создать уникальный и гармоничный интерьер.</p>
<p href="#" className="hover:cursor-pointer inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-yellow-700 rounded-lg hover:bg-yellow-800 focus:ring-4 focus:ring-blue-300 " onClick={handleScrollToSection2}>
Заказать
    <svg className="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
  </svg>
</p>
</div>
</div>


<div className='w-full flex justify-center mt-[10em]' ref={section1Ref}>
  <div className='w-[95%] lg:w-[65%]'>
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        <div className="grid gap-4">
            <div>
                <img className="h-auto max-w-full rounded-lg" src="/6.jpg" alt=""/>
            </div>
            <div>
                <img className="h-auto max-w-full rounded-lg" src="/18.jpg" alt=""/>
            </div>
            <div>
                <img className="h-auto max-w-full rounded-lg" src="/20.jpg" alt=""/>
            </div>
        </div>
        <div className="grid gap-4">
            <div>
                <img className="h-auto max-w-full rounded-lg" src="/9.jpg" alt=""/>
            </div>
            <div>
                <img className="h-auto max-w-full rounded-lg" src="/7.jpg" alt=""/>
            </div>
            <div>
                <img className="h-auto max-w-full rounded-lg" src="/11.jpg" alt=""/>
            </div>
        </div>
        <div className="grid gap-4">
            <div>
                <img className="h-auto max-w-full rounded-lg" src="/19.jpg" alt=""/>
            </div>
            <div>
                <img className="h-auto max-w-full rounded-lg" src="/15.jpg" alt=""/>
            </div>
            <div>
                <img className="h-auto max-w-full rounded-lg" src="/8.jpg" alt=""/>
            </div>
        </div>
        <div className="grid gap-4">
            <div>
                <img className="h-auto max-w-full rounded-lg" src="/13.jpg" alt=""/>
            </div>
            <div>
                <img className="h-auto max-w-full rounded-lg" src="/16.jpg" alt=""/>
            </div>
            <div>
                <img className="h-auto max-w-full rounded-lg" src="/17.jpg" alt=""/>
            </div>
        </div>
    </div>

  </div>
</div>



<div className='mt-[2em] w-full flex justify-center'>
<div className='w-[95%] lg:w-[65%]'>
<p className="text-gray-500 ">Наши специалисты работают с вами на всех этапах — от выбора материалов и дизайна до точных замеров и профессиональной установки. Мы используем только высококачественные материалы и современные технологии, чтобы ваши окна всегда выглядели идеально.</p>
<hr className="w-8 h-8 mx-auto my-8 bg-yellow-800 border-0 rounded md:my-12 "/>
  <blockquote className="text-xl italic font-semibold text-center text-gray-900 ">
      <p>"Наша компания — это идеальный выбор для тех, кто ценит качество и стиль. Мы предлагаем широкий ассортимент дизайнерских решений для вашего дома. Доверьтесь нам, и мы превратим ваши окна в произведение искусства."</p>
  </blockquote>
</div>
</div>

<div className='w-full flex justify-center mt-[10em]'>
  <div className='w-[95%] xl:w-full flex justify-center'>

  <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
      {/* Card 1 */}
      <div className="card bg-black w-full sm:w-96 shadow-xl">
        <figure>
          <img
            src="/14.jpg"
            alt="Shoes"
          />
        </figure>
        <div className="card-body">
          <h2 className="card-title">
          Дизайнерские шторы
            <div className="badge badge-secondary bg-yellow-500 border-3 border-yellow-500">Новинка</div>
          </h2>
          <p>Мы предлагаем разнообразие дизайнерских штор, которые идеально дополнят ваш интерьер. Наши шторы изготавливаются на заказ с учетом всех ваших пожеланий.</p>
          <div className="card-actions justify-end">
            <div className="badge badge-outline">Fashion</div>
            <div className="badge badge-outline">Products</div>
          </div>
        </div>
      </div>

      {/* Card 2 */}
      <div className="card bg-black w-full sm:w-96 shadow-xl">
        <figure>
          <img
            src="/12.jpg"
            alt="Shoes"
          />
        </figure>
        <div className="card-body">
          <h2 className="card-title">
          Жалюзи и карнизы
            <div className="badge badge-secondary bg-yellow-500 border-3 border-yellow-500">Новинка</div>
          </h2>
          <p>Наш ассортимент жалюзи и карнизов сочетает в себе стиль и функциональность. Мы поможем вам подобрать оптимальное решение для любого типа окон.</p>
          <div className="card-actions justify-end">
            <div className="badge badge-outline">Fashion</div>
            <div className="badge badge-outline">Products</div>
          </div>
        </div>
      </div>

      {/* Card 3 */}
      <div className="card bg-black w-full sm:w-96 shadow-xl">
        <figure>
          <img
            src="/10.jpg"
            alt="Shoes"
          />
        </figure>
        <div className="card-body">
          <h2 className="card-title">
          Тюли и текстиль
            <div className="badge badge-secondary bg-yellow-500 border-3 border-yellow-500">Новинка</div>
          </h2>
          <p>Элегантные тюли и качественный текстиль придадут вашему дому особую атмосферу уюта. Мы предлагаем широкий выбор материалов и расцветок.</p>
          <div className="card-actions justify-end">
            <div className="badge badge-outline">Fashion</div>
            <div className="badge badge-outline">Products</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<section className="mt-[7em]" ref={section2Ref}>
    <div className="container px-6 py-12 mx-auto">
        <div>
            <p className="font-medium text-yellow-600 ">Контакты</p>

            <h1 className="mt-2 text-2xl font-semibold text-gray-800 md:text-3xl ">Свяжитесь с нашей командой</h1>

            <p className="mt-3 text-gray-500 ">Мы с удовольствием ответим на ваши вопросы. Заполните эту форму или напишите нам на электронную почту.</p>
        </div>

        <div className="grid grid-cols-1 gap-12 mt-20 lg:grid-cols-2">
            <div className="grid grid-cols-1 gap-12 md:grid-cols-2">
                <div>
                    <span className="inline-block p-3 text-yellow-900 rounded-full bg-yellow-300/80 ">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                        </svg>
                    </span>

                    <h2 className="mt-4 text-base font-medium text-yellow-800 ">Почта</h2>
                    <p className="mt-2 text-sm text-yellow-900 ">pv.textil@mail.ru</p>
                </div>

            

                <div>
                    <span className="inline-block p-3 text-yellow-900 rounded-full bg-yellow-300/80 ">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                        </svg>
                    </span>
                    
                    <h2 className="mt-4 text-base font-medium text-yellow-800 ">Телефон</h2>
                    <p className="mt-2 text-sm text-yellow-900 ">+7 (916) 695-34-16</p>
                </div>
            </div>

            <div className="px-4 rounded-lg bg-gray-50">
            <form onSubmit={handleSubmit}>
      <div className="-mx-2 md:items-center md:flex">
        <div className="flex-1 px-2">
          <label className="block mb-2 text-sm text-gray-600">Имя</label>
          <input
            type="text"
            name="firstName"
            placeholder="Иван"
            className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg focus:outline-none focus:ring focus:ring-opacity-40"
          />
          <ValidationError prefix="FirstName" field="firstName" errors={state.errors} />
        </div>

        <div className="flex-1 px-2 mt-4 md:mt-0">
          <label className="block mb-2 text-sm text-gray-600">Фамилия</label>
          <input
            type="text"
            name="lastName"
            placeholder="Иванович"
            className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg focus:outline-none focus:ring focus:ring-opacity-40"
          />
          <ValidationError prefix="LastName" field="lastName" errors={state.errors} />
        </div>
      </div>

      <div className="mt-4">
        <label className="block mb-2 text-sm text-gray-600">Телефон</label>
        <input
          type="phone"
          name="phone"
          placeholder="+7 (916) 695-34-16"
          className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg focus:outline-none focus:ring focus:ring-opacity-40"
        />
        <ValidationError prefix="Phone" field="phone" errors={state.errors} />
      </div>

      <div className="w-full mt-4">
        <label className="block mb-2 text-sm text-gray-600">Сообщение</label>
        <textarea
          name="message"
          className="block w-full h-32 px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg md:h-56 focus:outline-none focus:ring focus:ring-opacity-40"
          placeholder="Опишите, чем мы можем помочь"
        ></textarea>
        <ValidationError prefix="Message" field="message" errors={state.errors} />
      </div>

      <button
        type="submit"
        disabled={state.submitting}
        className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-yellow-700 rounded-lg hover:bg-yellow-800 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
      >
        Отправить
      </button>
    </form>
            </div>
        </div>
    </div>
</section>





<footer className="bg-white  mt-[7em]">
    <div className="w-full mx-auto  p-4 md:flex md:items-center md:justify-between">
      <span className="text-sm text-gray-500 sm:text-center ">© 2023 <a href="https://Stylary.com/" className="hover:underline">Stylary™</a>. Все Права Защищены.
    </span>
    <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500  sm:mt-0">
        <li>
            <p href="#" className="hover:underline me-4 md:me-6">Москва</p>
        </li>
        {/* <li>
            <p href="#" className="hover:underline me-4 md:me-6">Privacy Policy</p>
        </li>
        <li>
            <p href="#" className="hover:underline me-4 md:me-6">Licensing</p>
        </li>
          <li>
            <p href="#" className="hover:underline">Contact</p>
        </li> */}
    </ul>
    </div>
</footer>



    </div>
  );
}

export default App;
